import Theme from '../style/config'
import Switch from './switch'

function Color() {
  
  return (
    Theme.color.title[Switch()]
  )

}

export default Color