import Theme from '../style/config';
import Switch from './switch';

function getBackground() {
  
  return (
    Theme.images[Switch()]
  )

}

export default getBackground